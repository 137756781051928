Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
Input = require 'components/common/input'
MdaTopicsStore = require 'stores/mda_topics_store'
mediator =  require 'mediator'
Spinner = require 'components/common/spinner_small'
{
  DOCUMENT_SECTIONS_V2_SECTION_TYPE
  DOCUMENT_SECTIONS_V2_CHAPTER_TYPE
} = require 'lib/db_docs/doc_types'
{ useState, useEffect, useMemo } = React
useTranslation = require 'components/hooks/use_translation'

isMissing = (needle) -> (container) -> 
  container?.get('title', '').toLowerCase().indexOf(needle.toLowerCase()) is -1

storeConnector =
  MdaTopicsStore: (Store) ->
    mdaTopicId: Store.getCurrentTopicId()
  DocumentSectionsV2Store: (Store) ->
    chapters: Store.getChapters()
    isFetching: Store.isFetchingSectionsForSearch()
    rootChaptersOrder: Store.getRootChaptersOrder()
    sections: Store.getSectionsForSearch()

SectionsSearch = ({ mdaTopicId, onCreate, chapters, rootChaptersOrder, sections, isFetching }) ->
  i18n = useTranslation('translation:actions')
  [ selectedItem, setSelectedItem ] = useState()
  [ search, updateSearch ] = useState('')

  handleCreate = ->
    onCreate(selectedItem) if selectedItem

  handleSectionSelect = (item) -> ->
    setSelectedItem(item)
    updateSearch(item.title)

  handlerSearchQueryChange = (evt) ->
    query = evt.target.value
    updateSearch(query)

  emptyResults = useMemo ->
    return true if isFetching and sections.isEmpty()
    chapters.every(isMissing(search)) and sections.every(isMissing(search))
  , [chapters, sections, search]

  useEffect ->
    DocumentSectionsV2Actions.fetchSectionsForSearch {
      projectId: mediator.project.id
      mdaTopicId
    }

    undefined
  , [mdaTopicId, mediator.project.id]

  <div className="sections-picker">
    <div>
      <div className="relative">
        <Input
          className={classNames 'sections-search p-5', { searching: isFetching }}
          onChange={handlerSearchQueryChange}
          value={search}
          type="search"
          placeholder={i18n 'search'}
          autoFocus
        />
        {isFetching and <Spinner />}
        {emptyResults and not _.isEmpty(search) and not isFetching and not selectedItem and (
          <div className="non-ideal-state italic">
            {i18n '/docsec:sections.no_matching_section_found'}
          </div>
        )}
      </div>
      <div className="chapters-list-search">
        {not isFetching and not _.isEmpty(search) and not sections.isEmpty() and <ChaptersList
          chapters={chapters}
          chaptersOrder={rootChaptersOrder}
          handleSectionSelect={handleSectionSelect}
          searchQuery={search}
          sections={sections}
          selectedItemId={selectedItem?.id}
        />}
      </div>
      <Button
        onClick={handleCreate}
        className="create px-15"
        label={i18n 'create'}
        disabled={not selectedItem?}
      />
    </div>
  </div>

ChaptersList = ({
  chapters,
  chaptersOrder,
  handleSectionSelect
  searchQuery,
  sections,
  selectedItemId,
}) ->
  <ol className="search-items">
    {chaptersOrder.map (chapterId) ->
      chapter = chapters.get chapterId

      return null unless chapter

      chapterTitle = chapter.get('title', '')
      subchaptersIds = chapter.get 'subchapters'

      chapterPayload = {
        id: chapterId,
        type: DOCUMENT_SECTIONS_V2_CHAPTER_TYPE,
        title: chapterTitle
      }

      chapterSections = chapter.get('sections', Immutable.List()).map (sectionId) ->
        sections.getIn [sectionId]

      itemClasses = classNames 'search-item',
        'fake-hidden': isMissing(searchQuery)(chapter) and
          chapterSections.every isMissing(searchQuery)

      <li key={chapterId} >
        <div className={itemClasses}>
          <div className={classNames 'title', selected: selectedItemId is chapterId} >
            <div onClick={handleSectionSelect(chapterPayload)}>{chapterTitle}</div>
          </div>
          <div className="ml-20">
            {chapter.get('sections', Immutable.List()).map (sectionId) ->
              section = sections.get sectionId

              return null unless section

              sectionTitle = section.get 'title'
              payload = {
                id: sectionId,
                type: DOCUMENT_SECTIONS_V2_SECTION_TYPE,
                title: sectionTitle
              }
              if not _.isEmpty(sectionTitle) and not isMissing(searchQuery)(sections.get(sectionId))
                <div
                  className={classNames 'section-title', selected: selectedItemId is sectionId}
                  key={sectionId}
                  onClick={handleSectionSelect(payload)}
                >
                  {sectionTitle}
                </div>
            }
          </div>
        </div>
        {if !subchaptersIds.isEmpty()
          <ChaptersList
            chapters={chapters}
            chaptersOrder={subchaptersIds}
            handleSectionSelect={handleSectionSelect}
            searchQuery={searchQuery}
            sections={sections}
            selectedItemId={selectedItemId}
          />
        }
      </li>
    }
  </ol>

SectionsSearch.propTypes = {
  chapters: PropTypes.instanceOf(Immutable.Map).isRequired,
  rootChaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired,
  sections: PropTypes.instanceOf(Immutable.Map).isRequired,
  onCreate: PropTypes.func.isRequired # (item: {id: string; title: string; type: string}) => void
  mdaTopicId: PropTypes.string
}

module.exports = ConnectStore SectionsSearch, 
  [MdaTopicsStore, DocumentSectionsV2Store],
  storeConnector
