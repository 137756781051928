Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
IconButton = require 'components/common/icon_button'
MDGTopicActions = require 'actions/mdg_topic_actions'
MDGTopicStore = require 'stores/mdg_topic_store'
mediator = require 'mediator'
Spinner = require 'components/common/spinner'
{ TopicCodesModal } = require 'components/common/topic_codes'
{ useCoffeeCallback, useCoffeeEffect, useI18n } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  MDGTopicStore: (Store) ->
    fetchingError: Store.getFetchingError()
    isFetching: Store.isFetching()
    topics: Store.getTopics()
    topicsInEditMode: Store.getTopicsInEditMode()

MDGTopicCodesButton = (isEmpty) -> ({ onClick }) ->
  i18n = useI18n()

  <Button
    className="mdg-topics__add-codes"
    label={i18n if isEmpty then 'actions.add_codes' else 'actions.edit_codes'}
    onClick={onClick}
    shape="pill"
  />

MDGTopic = ({ isEditing, projectId, topic }) ->
  i18n = useI18n('settings:mdg_topics')

  topicId = topic.get '_id'
  [name, setName] = useState topic.get 'name', ''

  useCoffeeEffect [isEditing, topic], ->
    setName topic.get 'name', ''

  onNameChange = (e) ->
    setName e.target.value

  onDelete = useCoffeeCallback [i18n, name, projectId, topicId], ->
    confirmOptions =
      title: i18n 'confirm_delete.title'
      message: i18n 'confirm_delete.message', { name }
      confirmText: i18n '/actions.delete'
      declineText: i18n '/actions.cancel'
      yesClass: 'danger'
    mediator.dialogs.confirm confirmOptions, (confirmed) ->
      return unless confirmed
      MDGTopicActions.deleteTopic projectId, topicId

  onSave = useCoffeeCallback [name, projectId, topicId], ->
    MDGTopicActions.update projectId, topicId, { name }

  onToggleEditing = useCoffeeCallback [topicId], ->
    MDGTopicActions.toggleEditForRow topicId

  onAddCodes = useCoffeeCallback [projectId, topicId], ({ codes }) ->
    MDGTopicActions.addCodes projectId, topicId, codes

  onRemoveCode = useCoffeeCallback [projectId, topicId], ({ code }) ->
    MDGTopicActions.removeCode projectId, topicId, code

  <div className="mdg-topics__topic">
    <div className="topic__name">
      {if isEditing
        <input
          autoFocus
          onChange={onNameChange}
          placeholder={i18n 'name_placeholder'}
          type="text"
          value={name}
        />
      else
        <span>{name}</span>
      }
    </div>
    <div className="topic__buttons">
      <TopicCodesModal
        buttonComponent={MDGTopicCodesButton topic.get('codes')?.isEmpty() ? true}
        className="topic__buttons__codes"
        codes={topic.get 'codes'}
        onAdd={onAddCodes}
        onRemove={onRemoveCode}
        parentId={topicId}
        title={i18n 'codes_modal_title', { name }}
      />
      {if isEditing
        <React.Fragment>
          <IconButton iconName="save" onClick={onSave} />
          <IconButton iconName="cross" onClick={onToggleEditing} />
        </React.Fragment>
      else
        <React.Fragment>
          <IconButton iconName="edit" onClick={onToggleEditing} />
          <IconButton iconName="delete" onClick={onDelete} />
        </React.Fragment>
      }
    </div>
  </div>

MDGTopic.propTypes =
  isEditing: PropTypes.bool.isRequired
  projectId: PropTypes.string.isRequired
  topic: PropTypes.instanceOf(Immutable.Map).isRequired

MDGTopics = ({ fetchError, isFetching, topics, topicsInEditMode }) ->
  i18n = useI18n('settings:mdg_topics')

  return <Spinner /> if isFetching
  return null if fetchError

  <div className="mdg-topics">
    {if topics.isEmpty()
      <div className="mdg-topics__no-topics">{i18n 'no_topics'}</div>
    else
      topics.valueSeq().map (topic) ->
        topicId = topic.get '_id'
        <MDGTopic
          isEditing={topicsInEditMode.includes topicId}
          key={topicId}
          projectId={mediator.project.id}
          topic={topic}
        />
    }
  </div>

MDGTopics.propTypes =
  fetchError: PropTypes.object
  isFetching: PropTypes.bool.isRequired
  topics: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
  topicsInEditMode: PropTypes.instanceOf(Immutable.List).isRequired

module.exports = ConnectStore MDGTopics, MDGTopicStore, storeConnector
