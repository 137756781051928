ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DiscardChangesActions = require 'actions/discard_changes_actions'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'
{ default: Editor } = Epiditor
{ useRef, useCallback, useState } = React

ReplyToComment = ({ sectionId, mdaTableId, parent, editorPluginProps, onReply }) ->
  i18n = useI18n('docsec:discussions')
  [focus, setFocus] = useState(false)
  editorViewRef = useRef(null)

  commentEditorRef = useCallback((editorView) ->
    editorViewRef.current = editorView
  , [])

  handleSaveComment = useCoffeeCallback [sectionId, mdaTableId, parent, editorViewRef], (e) ->
    e.stopPropagation()
    e.preventDefault()
    DocumentSectionsV2Actions.addDiscussionReply {
      sectionId,
      mdaTableId,
      parent,
      text: editorViewRef.current.state.toJSON()
    }
    .then () ->
      clearCommentReply()
      onReply?()

  clearCommentReply = useCoffeeCallback [editorViewRef], ->
    { state } = editorViewRef.current
    newState = state.apply(state.tr.delete(0, state.doc.content.size))
    editorViewRef.current.updateState(newState)
    setFocus false
    DiscardChangesActions.setHasChangesInComments false

  onFocus = useCoffeeCallback [],  ->
    setFocus true

  handleEditorChange = useCoffeeCallback [editorViewRef], ->
    { state } = editorViewRef.current
    # because of how prosemirror calculate size, it is the size of the content + 2 (start + end token)
    DiscardChangesActions.setHasChangesInComments(state.doc.content.size > 2) 
  
   handleEditorChangeDebounced = useCallback(
    _.debounce handleEditorChange, 250
    [handleEditorChange]
  )

  onBlur = ->
    _.delay ->
      setFocus(false)
    , 250

  <div>
    <div className="reply-to-comment-editor" >
      <Editor
        mode={'edit'}
        initialState={null}
        onLoad={commentEditorRef}
        onChange={handleEditorChangeDebounced}
        pluginProps={editorPluginProps}
        onFocus={onFocus}
        onBlur={onBlur}
        minimal
      />
    </div>
    {focus && <ApplyCancelButtons
      applyLabel={i18n '/actions.reply'}
      onApply={handleSaveComment}
      onCancel={clearCommentReply}
    />}
  </div>

module.exports = ReplyToComment
