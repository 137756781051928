useTranslation = require 'components/hooks/use_translation'
SectionsSearch = require './sections_search'
{ IconNames, ToolbarDivider, ToolbarControl } = Epiditor

SectionLinkEditor = ({ isNew, onCreate, onGoToSection, onRemoveLink }) ->
  i18n = useTranslation('docsec:sections')

  <div className="section-link-editor">
    {if isNew
      <SectionsSearch onCreate={onCreate} />
    else
      <div className="flex flex-row items-center p-5">
        <ToolbarControl
          onClick={onGoToSection}
          label={IconNames.Share}
          title={i18n 'go_to_section'}
         />
        <ToolbarDivider />
        <ToolbarControl
          onClick={onRemoveLink}
          label={IconNames.Unlink}
          title={i18n 'remove_section_link'}
        />
      </div>
    }
  </div>

SectionLinkEditor.propTypes = {
  isNew: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired # (item: {id: string; title: string; type: string}) => void
  onGoToSection: PropTypes.func.isRequired # () => void
  onRemoveLink: PropTypes.func.isRequired # () => void
}

module.exports = SectionLinkEditor
