var INITIAL_STATE, INITIAL_VIZ_CONFIG, NMAVizActions, NMA_OUTCOME_PREFIX, STANDARD_DENOMINATOR, generateStore,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

generateStore = require('stores/utils/generate_store');

NMA_OUTCOME_PREFIX = require('lib/doc_prefixes').nmaOutcome;

STANDARD_DENOMINATOR = NmaComponents.STANDARD_DENOMINATOR;

NMAVizActions = require('actions/nma_viz_actions');

INITIAL_VIZ_CONFIG = Immutable.fromJS({
  comparatorId: null,
  comparatorTableMode: 'CommonComparator',
  denominator: STANDARD_DENOMINATOR,
  selectedInterventionIds: [],
  selectedOutcomeIds: [],
  transposed: false,
  showThresholds: true
});

INITIAL_STATE = Immutable.fromJS({
  currentQuestionId: null,
  isExportModalOpen: false,
  isFetching: false,
  question: Immutable.Map(),
  outcomes: Immutable.List(),
  forestPlotOutcomeId: null,
  networkGraphOpen: false,
  vizConfig: INITIAL_VIZ_CONFIG
});

module.exports = generateStore({
  name: 'NMAVizStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAVizActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(_arg) {
      var commonComparator, firstInterventionId, outcomes, outcomesImm, question, questionId, questionImm;
      outcomes = _arg.outcomes, question = _arg.question;
      questionImm = Immutable.fromJS(question);
      outcomesImm = Immutable.fromJS(outcomes);
      questionId = questionImm.get('_id');
      firstInterventionId = questionImm.getIn(['interventions', 0, '_id']);
      commonComparator = questionImm.get('commonComparator') || firstInterventionId;
      return this.setState(this.state.withMutations(function(state) {
        state.set('outcomes', outcomesImm);
        state.set('question', questionImm);
        state.set('currentQuestionId', questionId);
        state.set('isFetching', false);
        state.setIn(['vizConfig', 'selectedInterventionIds'], questionImm.getIn(['interventions']).map(function(intervention) {
          return intervention.get('_id');
        }).filter(function(interventionId) {
          return interventionId !== commonComparator;
        }));
        state.setIn(['vizConfig', 'selectedOutcomeIds'], outcomesImm.filter(function(outcome) {
          return outcome.get('questionId') === questionId;
        }).map(function(outcome) {
          return outcome.get('_id');
        }));
        return state.setIn(['vizConfig', 'comparatorId'], commonComparator);
      }));
    },
    onDbChange: function(change) {
      if (change.id === this.state.getIn(['question', '_id'])) {
        return this.setState(this.state.withMutations(function(state) {
          var _ref;
          state.update('question', Immutable.fromJS((_ref = change.doc) != null ? _ref : {}));
          return state.updateIn(['vizConfig', 'selectedInterventionIds'], function(selectedInterventionIds) {
            var newInterventionIds;
            newInterventionIds = change.doc.interventions.map(function(intervention) {
              return intervention._id;
            });
            return selectedInterventionIds.filter(function(id) {
              return __indexOf.call(newInterventionIds, id) >= 0;
            });
          });
        }));
      } else if (change.id.indexOf(NMA_OUTCOME_PREFIX) === 0) {
        if (change.deleted) {
          return this.setState(this.state.withMutations(function(state) {
            state.update('outcomes', function(outcomes) {
              return outcomes["delete"](change.id);
            });
            return state.updateIn(['vizConfig', 'selectedOutcomeIds'], function(selectedOutcomeIds) {
              return selectedOutcomeIds.filter(function(id) {
                return id !== change.id;
              });
            });
          }));
        } else {
          return this.setState(this.state.update('outcomes', function(outcomes) {
            return outcomes.set(change.id, Immutable.fromJS(change.doc));
          }));
        }
      }
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    onOpenForestPlot: function(outcomeId) {
      return this.setState(this.state.set('forestPlotOutcomeId', outcomeId));
    },
    onCloseForestPlot: function() {
      return this.setState(this.state.set('forestPlotOutcomeId', null));
    },
    onOpenNetworkGraph: function() {
      return this.setState(this.state.set('networkGraphOpen', true));
    },
    onCloseNetworkGraph: function() {
      return this.setState(this.state.set('networkGraphOpen', false));
    },
    onOpenExportModal: function() {
      return this.setState(this.state.set('isExportModalOpen', true));
    },
    onCloseExportModal: function() {
      return this.setState(this.state.set('isExportModalOpen', false));
    },
    onSelectInterventionIds: function(interventionIds) {
      return this.setState(this.state.setIn(['vizConfig', 'selectedInterventionIds'], Immutable.fromJS(interventionIds)));
    },
    onSelectOutcomeIds: function(outcomeIds) {
      return this.setState(this.state.setIn(['vizConfig', 'selectedOutcomeIds'], Immutable.fromJS(outcomeIds)));
    },
    onSelectComparator: function(comparatorId) {
      var currentComparatorId, currentInterventionIds;
      currentComparatorId = this.state.getIn(['vizConfig', 'comparatorId']);
      if (currentComparatorId === comparatorId) {
        return;
      }
      currentInterventionIds = this.state.getIn(['vizConfig', 'selectedInterventionIds']);
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['vizConfig', 'comparatorId'], comparatorId);
        return state.setIn(['vizConfig', 'selectedInterventionIds'], currentInterventionIds.filter(function(interventionId) {
          return interventionId !== comparatorId;
        }).concat(Immutable.List([currentComparatorId])));
      }));
    },
    onSelectDenominator: function(denominator) {
      return this.setState(this.state.setIn(['vizConfig', 'denominator'], denominator));
    },
    onToggleTransposed: function() {
      return this.setState(this.state.updateIn(['vizConfig', 'transposed'], function(transposed) {
        return !transposed;
      }));
    },
    onToggleThresholds: function() {
      return this.setState(this.state.updateIn(['vizConfig', 'showThresholds'], function(current) {
        return !current;
      }));
    },
    onSetComparatorTableMode: function(mode) {
      return this.setState(this.state.setIn(['vizConfig', 'comparatorTableMode'], mode));
    },
    getOutcomes: function() {
      var currentQuestionId;
      currentQuestionId = this.state.getIn(['question', '_id']);
      return this.state.get('outcomes', Immutable.List()).filter(function(outcome) {
        return outcome.get('questionId') === currentQuestionId;
      });
    },
    getQuestion: function() {
      return this.state.get('question');
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    getForestPlotOutcomeId: function() {
      return this.state.get('forestPlotOutcomeId');
    },
    isNetworkGraphOpen: function() {
      return this.state.get('networkGraphOpen');
    },
    isExportModalOpen: function() {
      return this.state.get('isExportModalOpen');
    },
    getVizConfig: function() {
      return this.state.get('vizConfig');
    },
    getSelectedInterventionIds: function() {
      return this.state.getIn(['vizConfig', 'selectedInterventionIds']);
    },
    getSelectedOutcomeIds: function() {
      return this.state.getIn(['vizConfig', 'selectedOutcomeIds']);
    },
    getComparatorId: function() {
      return this.state.getIn(['vizConfig', 'comparatorId']);
    },
    getDenominator: function() {
      return this.state.getIn(['vizConfig', 'denominator']);
    },
    isTransposed: function() {
      return this.state.getIn(['vizConfig', 'transposed']);
    },
    getComparatorTableMode: function() {
      return this.state.getIn(['vizConfig', 'comparatorTableMode']);
    },
    getShowThresholds: function() {
      return this.state.getIn(['vizConfig', 'showThresholds']);
    }
  }
});
