ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
DocumentSectionsTitle = require 'components/document_sections/v2/document_sections_title'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
{ getSettingsOptions, getSettingsOrder } = require 'lib/document_sections_v2_helper'
mediator = require 'mediator'
RecommendationsPopulationsList =
  require 'components/dissemination/acp_visual_guidelines/recommendations_populations_list'
GeneralInformationSection =
  require 'components/dissemination/acp_visual_guidelines/general_information_section'
{ useI18n } = require 'lib/react_utils'
TextSection = require 'components/document_sections/v2/text_section'
{ Button } = ReactComponents

CustomSection = ({
  chapterId
  connectDragSource
  editingSection
  onDelete
  onInsertRecommendation
  readOnly
  scrollToSectionContent
  section
  view
}) ->

  handleTitleSave = (changedTitle) -> updateField('title', changedTitle)

  updateField = (key, value) ->
    projectId = mediator.project.id
    chapterId = section.get('parent')
    sectionId = section.get('_id')
    DocumentSectionsV2Actions.setSectionProperty(
      projectId, chapterId, sectionId, key, value
    )

  handleSettingSelect = (selectedSetting) ->
    sectionId = section.get('_id')
    switch selectedSetting
      when 'insert_recommendation'
        onInsertRecommendation({ sectionId })
      when 'delete'
        sectionId = section.get('_id')
        onDelete(sectionId)

  <React.Fragment>
    {switch section.get('sectionType')
      when 'acp_visual_guideline_general_information_form'
        <GeneralInformationSectionHeader  />
      when 'acp_recommendations_populations'
        <RecommendationPopulationsListHeader
          section={section}
          handleTitleSave={handleTitleSave}
        />
      else
        <RegularChapterSectionHeader
          connectDragSource={connectDragSource}
          handleSettingSelect={handleSettingSelect}
          handleTitleSave={handleTitleSave}
          section={section}
          view={view}
        />
    }
    <div>
      {switch (section.get('sectionType'))
        when 'acp_visual_guideline_general_information_form'
          <GeneralInformationSection
            section={section}
          />
        when 'acp_recommendations_populations'
          <RecommendationsPopulationsList
            section={section}
          />
        when 'visual_guideline_text_section'
          <TextSection
            chapterId={chapterId}
            connectDragSource={connectDragSource}
            editingSection={editingSection}
            hasChanges={false}
            onDelete={onDelete}
            onInsertMdaTable={_.noop}
            onInsertRecommendation={_.noop}
            readOnly={readOnly}
            scrollToSectionContent={scrollToSectionContent}
            section={section}
            suggesting={false}
            view={view}
            withHeader={false}
          />
        else null
      }
    </div>
  </React.Fragment>

RegularChapterSectionHeader = ({
  connectDragSource
  editable
  handleSettingSelect
  handleTitleSave
  section
  view
}) ->
  <div className="chapter-section-header">
    {connectDragSource(
      <div className="drag-marker" />
    )}
    <DocumentSectionsTitle
      onSave={handleTitleSave}
      sectionType={section.get('sectionType')}
      editable={editable}
      title={section.get('title')}
    />
    {<div className="chapter-section-management">
      <DropdownMenu
        opener={(
          <IconButton iconName="more" className="settings-toggle" />
        )}
        options={getSettingsOptions getSettingsOrder(section.get('sectionType'), view)}
        onSelect={handleSettingSelect}
      />
    </div>}
  </div>

RecommendationPopulationsListHeader = ({ section }) ->

  i18n = useI18n('vi:recommendations_populations')

  onInsertSubgroup = () ->
    ACPVisualGuidelinesActions.insertSubgroup section.get('_id')

  <div className="chapter-section-header">
    <div className="document-sections-title flex-grow">
      <h3>{i18n 'title'}</h3>
    </div>
    <Button
      className="add-population"
      onClick={onInsertSubgroup}
      label={i18n 'add_population'}
    />
  </div>

GeneralInformationSectionHeader = ({}) ->
  <div />

module.exports = CustomSection
