var CONFIG_OBJECT_MAP, DbHelper, NMAExportService, alt, gdtDataToNmaData, getOrganizationName, mapConfigObject;

alt = require('alt');

DbHelper = require('base/lib/db_helper');

gdtDataToNmaData = require('lib/nma_helper').gdtDataToNmaData;

getOrganizationName = require('lib/app_utils').getOrganizationName;

CONFIG_OBJECT_MAP = {
  'comparatorId': 'initialComparatorId',
  'denominator': 'initialDenominator',
  'selectedInterventionIds': 'initialInterventionIds',
  'selectedOutcomeIds': 'initialOutcomeIds',
  'transposed': 'initialTransposed'
};

mapConfigObject = function(props) {
  return _.chain(props).pairs().map(function(_arg) {
    var key, value, _ref;
    key = _arg[0], value = _arg[1];
    return [(_ref = CONFIG_OBJECT_MAP[key]) != null ? _ref : key, value];
  }).object().value();
};

module.exports = NMAExportService = (function() {
  function NMAExportService(adapter) {
    this.adapter = adapter;
  }

  NMAExportService.prototype._getNmaDataForPresentations = function(question, outcomes) {
    return gdtDataToNmaData(question, outcomes);
  };

  NMAExportService.prototype.exportToLink = function() {
    var data, organizationName, outcomes, presentationId, question, vizConfig;
    question = alt.stores.NMAVizStore.getQuestion();
    outcomes = alt.stores.NMAVizStore.getOutcomes();
    vizConfig = mapConfigObject(alt.stores.NMAVizStore.getVizConfig().toJS());
    data = this._getNmaDataForPresentations(question, outcomes);
    presentationId = question.get('_id');
    organizationName = getOrganizationName();
    return this.adapter.updateOrCreate(DbHelper.getNmaDbUrl(), presentationId, function(doc) {
      return _.extend({}, doc, {
        _id: presentationId,
        data: data,
        organizationName: organizationName,
        vizConfig: vizConfig
      });
    }).then(function() {
      return DbHelper.getNmaUrl(presentationId);
    });
  };

  NMAExportService.prototype.exportToIframe = function() {
    return this.exportToLink().then(function(url) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + url + "\" " + attrsString + "></iframe>";
    });
  };

  return NMAExportService;

})();
