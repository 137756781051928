var DocumentSectionsCommonActions, OrganizationsActions, OverarchingQuestionsActions, QuestionGroupsActions, QuestionsActions, QuestionsListComponentActions, QuestionsStatusesActions, W, alt, mediator;

OrganizationsActions = require('actions/organizations_actions');

OverarchingQuestionsActions = require('actions/overarching_questions_actions');

QuestionsStatusesActions = require('actions/questions_statuses_actions');

QuestionsActions = require('actions/questions_actions');

QuestionGroupsActions = require('actions/question_groups_actions');

DocumentSectionsCommonActions = require('actions/document_sections_common_actions');

alt = require('alt');

mediator = require('mediator');

W = require('when/when');

QuestionsListComponentActions = (function() {
  function QuestionsListComponentActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'toggleGroupEdit', 'deleteGroupSuccess', 'deleteGroupError', 'updateGroupCaptionSuccess', 'updateGroupCaptionError', 'updateQuestionsByGroupValue', 'sortQuestions', 'updateTextualData');
  }

  QuestionsListComponentActions.prototype.dbChange = function(change, _pending, _lastSeq) {
    QuestionsActions.dbChange(change);
    QuestionGroupsActions.dbChange(change);
    QuestionsStatusesActions.dbChange(change);
    OverarchingQuestionsActions.dbChange(change);
    return DocumentSectionsCommonActions.dbChange(change);
  };

  QuestionsListComponentActions.prototype.fetch = function(params) {
    var questionType, questionsCollectionType;
    if (params == null) {
      params = {};
    }
    questionsCollectionType = params.questionsCollectionType, questionType = params.questionType;
    questionsCollectionType = questionsCollectionType != null ? questionsCollectionType : 'regular';
    this.dispatch(questionsCollectionType);
    return W.all([
      QuestionsActions.fetch({
        questionType: questionType
      }), QuestionGroupsActions.fetch(questionsCollectionType), OverarchingQuestionsActions.fetch(mediator.project.id), OrganizationsActions.checkIfCurrentProjectIsInOrganization(), QuestionsStatusesActions.fetch(), DocumentSectionsCommonActions.fetch({
        dbId: mediator.project.id
      })
    ]).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  QuestionsListComponentActions.prototype.moveQuestionToGroup = function(data) {
    var questionType;
    questionType = this.alt.stores.QuestionsListComponentStore.getQuestionsCollectionType();
    return QuestionGroupsActions.moveQuestionToGroup(_.extend(data, {
      questionType: questionType
    }));
  };

  QuestionsListComponentActions.prototype.updateGroupCaption = function(data) {
    var groupId, questionType;
    this.dispatch(groupId);
    groupId = data.groupId;
    questionType = this.alt.stores.QuestionsListComponentStore.getQuestionsCollectionType();
    return QuestionGroupsActions.updateGroupCaption(_.extend(data, {
      questionType: questionType
    })).then((function(_this) {
      return function() {
        return _this.actions.updateGroupCaptionSuccess(groupId);
      };
    })(this))["catch"]((function(_this) {
      return function() {
        return _this.actions.updateGroupCaptionError(groupId);
      };
    })(this));
  };

  QuestionsListComponentActions.prototype.deleteGroup = function(groupId) {
    var questionType;
    this.dispatch(groupId);
    questionType = this.alt.stores.QuestionsListComponentStore.getQuestionsCollectionType();
    return QuestionGroupsActions.deleteGroup({
      groupId: groupId,
      questionType: questionType
    }).then((function(_this) {
      return function() {
        return _this.actions.deleteGroupSuccess(groupId);
      };
    })(this))["catch"]((function(_this) {
      return function() {
        return _this.actions.deleteGroupError(groupId);
      };
    })(this));
  };

  QuestionsListComponentActions.prototype.createGroup = function() {
    var questionType;
    questionType = this.alt.stores.QuestionsListComponentStore.getQuestionsCollectionType();
    return QuestionGroupsActions.createGroup({
      questionType: questionType
    });
  };

  QuestionsListComponentActions.prototype.resetStore = function() {
    return this.alt.recycle('QuestionsListComponentStore');
  };

  QuestionsListComponentActions.prototype.updateQuestionPosition = function(_arg) {
    var QuestionsListComponentStore, currentOrderNumber, groupId, initialOrderNumber, landedGroupId, landedGroupQuestions, qId, questionType;
    qId = _arg.qId, groupId = _arg.groupId;
    QuestionsListComponentStore = this.alt.stores.QuestionsListComponentStore;
    questionType = QuestionsListComponentStore.getQuestionsCollectionType();
    landedGroupId = QuestionsListComponentStore.getCurrentDraggedOverGroupId();
    landedGroupQuestions = QuestionsListComponentStore.getGroupQuestions(landedGroupId);
    initialOrderNumber = QuestionsListComponentStore.getDraggingQuestion().get('orderNumber');
    currentOrderNumber = landedGroupQuestions.getIn([qId, 'orderNumber']);
    if (landedGroupId === groupId && currentOrderNumber === initialOrderNumber) {
      return W.resolve();
    }
    return QuestionGroupsActions.updateQuestionPosition({
      orderNumber: currentOrderNumber,
      groupId: landedGroupId,
      questionType: questionType,
      qId: qId
    });
  };

  QuestionsListComponentActions.prototype.updateList = function(_arg) {
    var questionGroups, questions, shouldSort;
    questions = _arg.questions, questionGroups = _arg.questionGroups;
    shouldSort = this.alt.stores.QuestionsListComponentStore.shouldSort({
      questions: questions,
      questionGroups: questionGroups
    });
    if (shouldSort) {
      return this.actions.sortQuestions({
        questions: questions,
        questionGroups: questionGroups
      });
    } else {
      return this.actions.updateTextualData({
        questions: questions,
        questionGroups: questionGroups
      });
    }
  };

  return QuestionsListComponentActions;

})();

module.exports = alt.createActions(QuestionsListComponentActions);
