appUtils = require 'lib/app_utils'
mediator = require 'mediator'
ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
DropdownMenu = require 'components/common/dropdown_menu'
IconButton = require 'components/common/icon_button'
useTranslation = require 'components/hooks/use_translation'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
VisibilityMarks = require 'components/document_sections/v2/discussions/visibility_marks'
CommentToolbar = require 'components/document_sections/v2/discussions/comment_toolbar'
ReplyToComment = require 'components/document_sections/v2/discussions/reply_to_comment'
{ scrollIntoView } = require 'lib/epiditor_utils'
{ getAccessRightsList } = require 'lib/members_helper'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
immUtils = require 'lib/immutable_utils'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'
{ default: Editor, serializeState: serializeEditorState } = Epiditor
{ useRef, useCallback, useMemo, useEffect, useState } = React

actionsMenuPosition = {
  position: 'bottom'
  alignment: 'end'
}

CommentItem = React.memo ({
  activeDiscussionItem,
  data,
  editingDiscussionItems,
  editingReplyId,
  isDraft,
  mdaTableId,
  nestedComment,
  sectionId,
}) ->
  i18n = useI18n('')
  elRef = useRef(null)
  commentEditorContainerRef = useRef(null)
  {
    id
    resolved
    done
    time
    data: itemData
    author
    from
    parentId
    visibleFor
  } = data.toJS()
  [overflowDetected, setOverflowDetetected] = useState(false)
  [commentExpanded, setCommentExpanded] = useState(false)

  editing = editingDiscussionItems.has(id)

  { text } = itemData || {}
  initialContent = if editing
    content = editingDiscussionItems.getIn([id, 'content'], Immutable.Map())
    if immUtils.isImmutable(content) then content.toJS() else content
  else
    text

  active = if _.isArray(activeDiscussionItem)
    _.includes(activeDiscussionItem, id)
  else
    activeDiscussionItem is id

  editorViewRef = useRef(null)

  commentEditorRef = useCallback((editorView) ->
    editorViewRef.current = editorView
  , [])

  itemActions = useCoffeeMemo [author.id, resolved, i18n], ->
    isCurrentUserAdmin = author.id is mediator.user.get('_id')
    hasUserAdminLikeAccessRight = mediator.user.hasRole ['admin', 'mdg_editor_in_chief']
    _.chain [
      'edit',
      (if resolved then 'reopen' else 'resolve'),
      (if done then 'not_done' else 'done'),
      'delete'
    ]
    .filter (value) ->
      if nestedComment then value not in ['done' ,'not_done']
      else true
    .map (value) ->
      if (isCurrentUserAdmin and value not in ['resolve', 'reopen', 'done', 'not_done']) or hasUserAdminLikeAccessRight
        {
          value,
          text: i18n "docsec:comment_actions.#{value}"
        }
    .compact()
    .value()

  updateItem = (updatedFields) ->
    if editorViewRef.current
      payload = {
        id
        sectionId
        mdaTableId
        discussionType: 'comments'
        updatedData: updatedFields
        isDraft
      }
      DocumentSectionsV2Actions.saveDiscussionItem _.extend payload, {
        projectId: mediator.project.id
      }

  removeItem = ->
    payload = { item: data.toJS(), mdaTableId, sectionId, discussionType: 'comments' }
    if isDraft
      DocumentSectionsV2Actions.removeDraftDiscussion payload
    else
      DocumentSectionsV2Actions.removeDiscussionItem _.extend payload, {
        projectId: mediator.project.id
      }

  handleAction = (action) ->
    switch action
      when 'edit'
        DocumentSectionsV2Actions.editDiscussion { id, content: text }
        openComment()
      when 'resolve'
        updateItem resolved: true
      when 'done'
        updateItem done: true
      when 'not_done'
        updateItem done: false
      when 'delete'
        removeItem()
      when 'reopen'
        updateItem resolved: false

  closeCommentEdit = ->
    DocumentSectionsV2Actions.closeDiscussionEdit id

  handleCancel = (evt) ->
    evt.stopPropagation()
    if editing?.isNew
      removeItem()
    else
      closeCommentEdit()

  editorPluginProps = useMemo(
    ->
      annotations: { disabled: true }
    []
  )

  handleSaveComment = (evt) ->
    evt.stopPropagation()
    newItemData = _.extend {}, itemData, text: editorViewRef.current.state.toJSON()
    updateItem data: newItemData
    closeCommentEdit()

  handleClick = (evt) ->
    return if editing
    evt.preventDefault()
    evt.stopPropagation()
    scrollToContent = { sectionId, contentPos: from }
    projectId = mediator.project.id
    if active
      DocumentSectionsV2Actions.setScrollToSectionContent _.extend scrollToContent, { projectId }
    else
      DocumentSectionsV2Actions.setActiveDiscussionItem { projectId, id: [id], scrollToContent }

  toggleCommentVisibility = useCoffeeCallback [data], (e) ->
    e.stopPropagation()
    e.preventDefault()
    # reset visibility to default state, sort of uncheck
    newRole = e.target.getAttribute('data-role')
    visibleFor = if _.contains(visibleFor or [], newRole)
      _.without(visibleFor or [], newRole)
    else
      (visibleFor or []).concat newRole
    updateItem { visibleFor }

  # scroll this item into view when it gets activated
  useEffect(->
    scrollIntoView(elRef.current) if active and elRef.current
    return
  , [active])

  showReplyInput = useCoffeeMemo [editing, nestedComment, active, id], ->
    not editing and not nestedComment and active and not _.isEmpty id

  replies = data.get('replies', Immutable.List())

  useEffect(->
    if commentEditorContainerRef.current
      el = commentEditorContainerRef.current
      if el.getBoundingClientRect().height > 100
        setOverflowDetetected(true)
    return
  , [])

  openComment = ->
    setCommentExpanded(true)

  closeComment = ->
    setCommentExpanded(false)

  commentEditorContainerClasses = classNames 'comment-editor-container', 'overflow-hidden', 
    'overflow-detected': overflowDetected
    'expanded': editing or commentExpanded

  handleEditorChangeDebounced = useCallback(
    _.debounce handleEditorChange, 250
    [handleEditorChange]
  )

  handleEditorChange = useCallback((changedValue) ->
    if not editing
      return
    if not _.isEqual text, serializeEditorState(changedValue)
      DocumentSectionsV2Actions.updateDiscussion { id, content: serializeEditorState(changedValue) }
  , [id, editing])

  <div>
    <div
      ref={elRef}
      onClick={handleClick}
      className={classNames 'discussion-item', 'comment', {
        'active': active and !nestedComment,
        resolved,
        done,
        editing,
        nestedComment
      }}
    >
      <div className="flex flex-row items-center">
        <div className="author-name flex-1 bold truncate">
          {author?.name}
        </div>
        <div className="flex-none">
          <span className="timestamp mr-10">{moment(parseInt(time)).format('DD MMM YYYY')}</span>
          {not editing and not _.isEmpty(itemActions) and <DropdownMenu
            opener={<IconButton iconName="more" className="more-icon" />}
            options={itemActions}
            onSelect={handleAction}
            menuPosition={actionsMenuPosition}
          />}
        </div>
      </div>
      <div>
        <DynamicHeightContainer>
          <div className={commentEditorContainerClasses} ref={commentEditorContainerRef}>
            <Editor
              mode={if editing then 'edit' else 'readonly'}
              initialState={initialContent}
              onLoad={commentEditorRef}
              pluginProps={editorPluginProps}
              onChange={handleEditorChange}
              minimal
            />
          </div>
        </DynamicHeightContainer>
        {not editing and overflowDetected and <div className="text-right">
          <button className="show-more-btn" onClick={if commentExpanded then closeComment else openComment}>
            {i18n if commentExpanded then 'actions.show_less' else 'actions.show_more'}
          </button>
          </div>
        }
        <CommentToolbar
          editing={editing}
          handleCancel={handleCancel}
          handleSaveComment={handleSaveComment}
          nestedComment={nestedComment}
          toggleCommentVisibility={toggleCommentVisibility}
          visibleFor={visibleFor}
        />
      </div>
      {not replies.isEmpty() and
        <div className="comment-replies">
          {replies.map (reply) ->
            <CommentItem
              key={reply.get('id')}
              activeDiscussionItem={activeDiscussionItem}
              data={reply.get('data')}
              editingDiscussionItems={editingDiscussionItems}
              isDraft={reply.get('isDraft')}
              mdaTableId={mdaTableId}
              sectionId={sectionId}
              nestedComment
            />
          }
        </div>
      }
      {showReplyInput and <div className="comment__reply-container">
        <ReplyToComment
          sectionId={sectionId}
          mdaTableId={mdaTableId}
          parent={data.toJS()}
          editorPluginProps={editorPluginProps}
        />
      </div>}
    </div>
  </div>

CommentItem.propTypes =
  data: PropTypes.instanceOf(Immutable.Map).isRequired
  sectionId: PropTypes.string.isRequired
  editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
  active: PropTypes.bool
  isDraft: PropTypes.bool
  editingReplyId: PropTypes.string
  nestedComment: PropTypes.bool

module.exports = CommentItem
