var DbDoc, MDG_TOPIC_PREFIX, mdgTopicShape;

DbDoc = require('lib/db_docs/db_doc');

mdgTopicShape = require('lib/db_docs/doc_shapes/mdg_topic_doc_shape');

MDG_TOPIC_PREFIX = require('lib/doc_prefixes').mdgTopic;

module.exports = DbDoc(mdgTopicShape, {
  dbSetup: {
    idPrefix: MDG_TOPIC_PREFIX
  },
  methods: {
    addCodes: function(codesMap) {
      return this.updateAt('codes')(function(existingCodes) {
        return _.extend({}, existingCodes, codesMap);
      });
    },
    removeCode: function(codeId) {
      return this.updateAt('codes')(function(codes) {
        return _.omit(codes, codeId);
      });
    }
  }
});
