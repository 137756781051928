ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdStore = require 'stores/etd_store'
{ extractionFormShape } = require 'components/covid_extraction_form/extraction_form'
mediator = require 'mediator'
RecommendationMetadataFormModal = require './recommendation_metadata_form_modal'
Translation = require 'components/mixins/translation'
{
  COVID_ASTERIXED_FIELDS
  getMetadataForm
} = require 'lib/covid_extraction_form_helper'

getAsterixedFields = ->
  return [] unless mediator.services.switches.isServerSwitchOn 'covidFeatures'
  COVID_ASTERIXED_FIELDS

storeConnector =
  EtdStore: (Store) ->
    asterixedFields = getAsterixedFields()
    metadataForm = getMetadataForm Store.getTemplateId()
    isAnyRecommendationMetadataFilled = Store.isAnyRecommendationMetadataFilled metadataForm
    { asterixedFields, isAnyRecommendationMetadataFilled, metadataForm }

RecommendationMetadata = createReactClass
  displayName: 'RecommendationMetadata'

  mixins: [
    CustomRenderMixin
    Translation('')
  ]

  propTypes:
    editable: PropTypes.bool.isRequired
    isAnyRecommendationMetadataFilled: PropTypes.bool.isRequired
    metadataForm: extractionFormShape
    asterixedFields: PropTypes.arrayOf(PropTypes.string)

  getInitialState: ->
    showExtractionModal: false

  openExtractionModal: ->
    @setState showExtractionModal: true

  closeExtractionModal: ->
    @setState showExtractionModal: false

  render: ->
    { asterixedFields, editable, isAnyRecommendationMetadataFilled, metadataForm } = @props

    recommendationMetadataButtonLabel = if isAnyRecommendationMetadataFilled
      'actions.edit_recommendation_metadata'
    else
      'actions.add_recommendation_metadata'

    unless _.isEmpty(metadataForm)
      <div>
        <button
          className="btn btn-extraction"
          disabled={not editable}
          onClick={@openExtractionModal}
        >
          {@i18n recommendationMetadataButtonLabel}
        </button>

        <RecommendationMetadataFormModal
          asterixedFields={asterixedFields}
          isOpen={@state.showExtractionModal}
          form={metadataForm}
          onClose={@closeExtractionModal}
        />
      </div>
    else
      null

module.exports = ConnectStore RecommendationMetadata, EtdStore, storeConnector
