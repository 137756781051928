var MDG_TOPIC, codeType, docShape, initial, mapOf, mdgTopicShape, number, optional, shape, string, _ref;

codeType = require('lib/db_docs/field_types/code_type');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

mapOf = require('lib/db_docs/field_types/map_of');

MDG_TOPIC = require('lib/db_docs/doc_types').MDG_TOPIC;

_ref = require('lib/db_docs/field_types/built_in_types'), number = _ref.number, string = _ref.string;

shape = require('lib/db_docs/field_types/shape');

optional = shape.typeDecorators.optional;

mdgTopicShape = docShape({
  codes: optional(mapOf(codeType)),
  docType: initial(MDG_TOPIC)(string),
  name: initial('')(string),
  orderNumber: initial(1)(number)
});

module.exports = mdgTopicShape;
