AdolopedEtdStore = require 'stores/adoloped_etd_store'
AdolopmentDataStore = require 'stores/adolopment_data_store'
ConnectStore = require 'components/enhancers/connect_store'
CreateStoreMixin = require '../mixins/create_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EpPresentations = window.epPresentations
EtdActions = require 'actions/etd_actions'
etdHelper = require 'lib/etd_helper'
EtdsStore = require 'stores/etds_store'
EtdStore = require 'stores/etd_store'
InfoIconWithTooltip = require 'components/common/info_icon_with_tooltip'
mediator = require 'mediator'
OrganizationsStore = require 'stores/organizations_store'
ProgressSelect = require 'components/evidence_syntheses/progress_select'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsActions = require 'actions/questions_actions'
QuestionsHelper = require 'lib/questions_helper'
QuestionsStatusesStore = require 'stores/questions_statuses_store'
QuestionsStore = require 'stores/questions_store'
ReferencesStore = require 'stores/references_store'
Router = require 'router'
Select = require 'components/common/select_custom'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ getRelatedRecommendations } = require 'lib/etds_helper'
{ preparePresentationsData } = require 'lib/presentations_helper'
{ useI18n } = require 'lib/react_utils'
{ getMetadataForm } = require 'lib/covid_extraction_form_helper'

storeConnectors =
  AdolopedEtdStore: (Store) ->
    adolopments: Store.getAdolopments()
    isFetchingAdolopment: Store.isFetching()
  AdolopmentDataStore: (Store) ->
    adolopmentData: Store.getAdolopmentData()
  EtdStore: (EtdStore, ownProps) ->

    metadataForm = getMetadataForm EtdStore.getTemplateId()

    attachments: EtdStore.getAttachments()
    assessment: EtdStore.getAssessment()
    assessmentSections: EtdStore.getAssessmentSections()
    backgroundDetailSections: EtdStore.getBackgroundDetailSections()
    clinicians: EtdStore.getPresentationSection('clinicians')
    conclusions: EtdStore.getConclusions()
    currentEditable: EtdStore.getActiveContentEditable()
    isFetchingEtd: EtdStore.isFetching()
    isEditable: EtdStore.isEditable()
    patients: EtdStore.getPresentationSection('patients')
    policymakers: EtdStore.getPresentationSection('policymakers')
    presentationData: EtdStore.getPresentationData()
    presentationType: ownProps.presentationType or EtdStore.getPresentationType()
    recommendationMetadata: EtdStore.getRecommendationMetadata(metadataForm)
    question: EtdStore.getQuestion()
    recommendation: EtdStore.getRecommendation()
    relatedQuestionsIds: EtdStore.getRelatedQuestionsIds()
    isIsofVisibleInPremiumView: EtdStore.isIsofVisibleInPremiumView()
    templateUndefined: EtdStore.isTemplateUndefined()
    templateEmpty: EtdStore.isTemplateEmpty()
    templateId: EtdStore.getTemplateId()
  EtdsStore: (Store) ->
    isFetchingEtds: Store.isFetching()
    etds: Store.getEtds()
  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()
  QuestionsStore: (QuestionsStore) ->
    isofData: QuestionsStore.getIsofQuestionModel()
    questionData: QuestionsStore.getQuestion()
    questions: QuestionsStore.getQuestions()
  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups()
  QuestionsStatusesStore: (Store) ->
    questionsStatuses: Store.getQuestionsStatuses()
  ReferencesStore: (Store) ->
    isFetchingReferences: Store.isFetching()

Presentations = createReactClass
  displayName: "Presentations"

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.presentations')
  ]

  presentationOptions: ->
    withPremiumPresentations = mediator.services.switches.isOn 'premium-presentations'
    options = @props.presentationData.keySeq().map (key) =>
      {value: key, text: @i18n "types.#{key}"}
    .toJS()
    return options unless withPremiumPresentations
    options.concat([{value: 'premium-patients', text: @i18n "types.premium_patients"}])

  getDefaultProps: ->
    projectFromOrganization: false
    questionsStatuses: Immutable.Map()

  onChangePresentationType: (value) ->
    EtdActions.updatePresentationType value

  onSavePlainLanguageStatement: (outcomeId, text) ->
    questionId = @props.questionData.get '_id'
    QuestionsActions.updatePlainLanguageSummary { questionId, outcomeId, text }

  isFetching: ->
    { isFetchingEtd, isFetchingEtds, isFetchingAdolopment, isFetchingReferences } = @props
    isFetchingEtd or isFetchingEtds or isFetchingAdolopment or isFetchingReferences

  toggleSofTableInPremiumView: ->
    EtdActions.toggleSofTableInPremiumView()

  showPresentationsSwitch: ->
    not mediator.services.switches.isServerSwitchOn('ecFeatures')

  getDocumentType: ->
    switch @props.questionData.get('type')
      when 'good-practice-statement' then 'gps'
      when 'additional-guidance' then 'additional-guidance'
      else 'regular'

  render: ->

    { templateUndefined, templateEmpty } = @props

    return <Spinner /> if @isFetching()
    return <TemplateEmpty /> if templateEmpty or templateUndefined

    {
      adolopmentData
      adolopments
      assessment
      assessmentSections
      attachments
      backgroundDetailSections
      clinicians
      conclusions
      currentEditable
      etds
      isEditable
      isIsofVisibleInPremiumView
      isofData
      patients
      policymakers
      presentationData
      presentationTitle
      presentationType
      projectFromOrganization
      question
      questionData
      questionGroups
      questions
      questionsStatuses
      recommendation
      recommendationMetadata
      relatedQuestionsIds
      templateId
    } = @props

    relatedQuestions = QuestionsHelper.getRelatedQuestions questionGroups, questions,
      relatedQuestionsIds
    relatedRecommendations = getRelatedRecommendations(etds, relatedQuestions)
    questionId = questionData.get('_id')
    showProgressStatus =  projectFromOrganization and questionId

    { attachments, assessment, conclusions } = preparePresentationsData(
      assessment,
      attachments,
      conclusions,
      adolopments,
      adolopmentData,
      questionData
    )

    <div>
      <div>
        {@showPresentationsSwitch() and <div className='presentation-type-select-container'>
          <div>
            <InfoIconWithTooltip info={@i18n 'presentations_info'} />
            <span className='presentation-type-select-label'>
              {@i18n 'select_presentation_type'}
            </span>
            <Select
              options={@presentationOptions()}
              selected={presentationType}
              onChange={@onChangePresentationType}
              listClassName='presentation-options'
            />
          </div>
          {showProgressStatus and
            <ProgressSelect
              groupKey={'presentationStatus'}
              questionId={questionId}
              questionStatuses={questionsStatuses.get(questionId)}
            />
          }
        </div>}
        {!presentationData.isEmpty() && <EpPresentations
          actions=EtdActions
          assessment={assessment}
          assessmentSections={assessmentSections}
          attachments={attachments}
          backgroundDetailSections={backgroundDetailSections}
          clinicians={clinicians}
          conclusions={conclusions}
          currentEditable={currentEditable}
          documentType={@getDocumentType()}
          isEditable={isEditable}
          isofData={isofData}
          isIsofVisibleInPremiumView={isIsofVisibleInPremiumView}
          onSavePlainLanguageStatement={@onSavePlainLanguageStatement}
          patients={patients}
          policymakers={policymakers}
          presentationTitle={presentationTitle}
          presentationType={presentationType}
          presentationData={presentationData}
          question={question}
          questionData={questionData}
          recommendation={recommendation}
          recommendationMetadata={Immutable.fromJS(recommendationMetadata)}
          references={etdHelper.getReferences()}
          relatedQuestions={relatedQuestions}
          relatedRecommendations={relatedRecommendations}
          sectionTabs={[]}
          toggleSofTableInPremiumView={@toggleSofTableInPremiumView}
        />}
      </div>
    </div>

TemplateEmpty = ->

  i18n = useI18n('es:recommendations.presentations')

  goToRecommendationsModule = (e) ->
    e.preventDefault()
    mediator.publish '!router:route',
      Router::routeForSubmodule('evidence-syntheses', 'recommendations')

  <div>
    {i18n 'fill_recommendation', {},
      link: <a href='#' onClick={goToRecommendationsModule}>{i18n 'recommendations'}</a>
    }
  </div>


module.exports = ConnectStore(
  Presentations
  [
    AdolopmentDataStore,
    AdolopedEtdStore,
    EtdStore,
    EtdsStore,
    OrganizationsStore,
    QuestionsStore,
    QuestionGroupsStore,
    QuestionsStatusesStore
    ReferencesStore
  ]
  storeConnectors
)
