ConnectStore = require 'components/enhancers/connect_store'
{ gdtDataToNmaData, nmaDataToVizData } = require 'lib/nma_helper'
mediator = require 'mediator'
Modal = require 'components/common/modal'
NMAVizActions = require 'actions/nma_viz_actions'
NMAVizStore = require 'stores/nma_viz_store'
Router = require 'router'
Spinner = require 'components/common/spinner'
{
  CommonComparatorTableWithI18n
  ForestPlotWithI18n
  NetworkGraphWithI18n
  SidebarButton
  SidebarWithI18n
  TableSettingsContext
} = NmaComponents
{ useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'
{ useState } = React

storeConnector =
  NMAVizStore: (Store) ->
    comparatorId: Store.getComparatorId()
    comparatorTableMode: Store.getComparatorTableMode()
    denominator: Store.getDenominator()
    forestPlotOutcomeId: Store.getForestPlotOutcomeId()
    isFetching: Store.isFetching()
    isNetworkGraphOpen: Store.isNetworkGraphOpen()
    outcomes: Store.getOutcomes()
    question: Store.getQuestion()
    selectedInterventionIds: Store.getSelectedInterventionIds()
    selectedOutcomeIds: Store.getSelectedOutcomeIds()
    showThresholds: Store.getShowThresholds()
    transposed: Store.isTransposed()

NMAViz = ({
  comparatorId
  comparatorTableMode
  denominator
  forestPlotOutcomeId
  isFetching
  isNetworkGraphOpen
  outcomes: outcomesFromStore
  question
  selectedInterventionIds
  selectedOutcomeIds
  showThresholds
  transposed
}) ->
  [isSidebarOpen, setIsSidebarOpen] = useState false

  [expandedRows, setExpandedRows] = useState []

  onExpand = useCoffeeCallback [expandedRows], (rowId) ->
    setExpandedRows (prev) ->
      if rowId in prev
        prev.filter (id) -> id isnt rowId
      else
        prev.concat rowId

  { interventions, comparisons, outcomes, explanations } = useCoffeeMemo [outcomesFromStore, question], ->
    gdtDataToNmaData question, outcomesFromStore

  outcomesToPass = useCoffeeMemo [outcomes, selectedOutcomeIds], ->
    outcomes.filter (outcome) -> selectedOutcomeIds.includes outcome.id

  tableSettingsContextValue = useCoffeeMemo [denominator, outcomes, showThresholds], ->
    { denominator, outcomes, showThresholds }

  openSidebar = useCoffeeCallback [], ->
    setIsSidebarOpen true

  closeSidebar = useCoffeeCallback [], ->
    setIsSidebarOpen false

  openLeagueTable = useCoffeeCallback [question], (outcomeId) ->
    route = Router::getProjectRelativeUrl "/nma/#{question.get('_id')}/outcomes/#{outcomeId}"
    mediator.publish '!router:route', route

  data = useCoffeeMemo [comparatorId, comparisons, interventions, selectedInterventionIds, comparatorTableMode], ->
    selectedInterventions = interventions.filter (intervention) ->
      selectedInterventionIds.includes intervention.id
    nmaDataToVizData interventions, selectedInterventions, comparisons, comparatorId, comparatorTableMode

  networkGraphData = useCoffeeMemo [outcomesFromStore, question], ->
    gdtDataToNmaData question, outcomesFromStore

  return <Spinner /> if isFetching

  <div className="nma-viz">
    <TableSettingsContext.Provider value={tableSettingsContextValue}>
      <CommonComparatorTableWithI18n
        comparatorTableMode={comparatorTableMode}
        data={data}
        expandedRows={expandedRows}
        expandDetails={onExpand}
        explanations={explanations}
        forestPlotSelected={NMAVizActions.openForestPlot}
        i18nInstance={i18next}
        leagueTableSelected={openLeagueTable}
        outcomes={outcomesToPass}
        showCoeInsufficientLabel={mediator.services.switches.isServerSwitchOn('acpFeatures')}
        transposed={transposed}
        thresholdType={question.get('thresholdOptions')}
      />
      <SidebarWithI18n
        comparatorId={comparatorId}
        comparatorTableMode={comparatorTableMode}
        denominator={denominator}
        i18nInstance={i18next}
        interventions={interventions}
        isVisible={isSidebarOpen}
        onComparatorChange={NMAVizActions.selectComparator}
        onDenominatorChange={NMAVizActions.selectDenominator}
        onHide={closeSidebar}
        onSelectedInterventionsChange={NMAVizActions.selectInterventionIds}
        onSelectedOutcomesChange={NMAVizActions.selectOutcomeIds}
        onToggleTransposed={NMAVizActions.toggleTransposed}
        outcomes={outcomes}
        setComparatorTableMode={NMAVizActions.setComparatorTableMode}
        selectedInterventions={selectedInterventionIds.toJS()}
        selectedOutcomes={selectedOutcomeIds.toJS()}
        toggleThresholds={NMAVizActions.toggleThresholds}
      />
      {forestPlotOutcomeId? and <Modal
        className="nma-viz-plot"
        closeButton
        isOpen
        onClose={NMAVizActions.closeForestPlot}
      >
        <ForestPlotWithI18n
          comparisonName={interventions.find(({ id }) -> id is comparatorId).name}
          data={data}
          i18nInstance={i18next}
          outcome={outcomes.find ({ id }) -> id is forestPlotOutcomeId}
        />
      </Modal>}
      {isNetworkGraphOpen and <Modal
        className="nma-viz-plot"
        closeButton
        isOpen
        onClose={NMAVizActions.closeNetworkGraph}
      >
        <NetworkGraphWithI18n data={networkGraphData} i18nInstance={i18next} />
      </Modal>}
    </TableSettingsContext.Provider>
    <span className="nma-viz__sidebar-button">
      <SidebarButton onClick={openSidebar} />
    </span>
  </div>

NMAViz.propTypes =
  comparatorId: PropTypes.string
  denominator: PropTypes.number.isRequired
  isFetching: PropTypes.bool.isRequired
  outcomes: PropTypes.instanceOf(Immutable.List).isRequired
  question: PropTypes.instanceOf(Immutable.Map).isRequired
  selectedInterventionIds: PropTypes.instanceOf(Immutable.List).isRequired
  selectedOutcomeIds: PropTypes.instanceOf(Immutable.List).isRequired
  transposed: PropTypes.bool.isRequired

module.exports = ConnectStore NMAViz, NMAVizStore, storeConnector
